import './colorstripe.css';
import * as React from "react"

export interface colorstripeProps {
    color: string
}

export default function colorstripe(props: colorstripeProps) {
    const { color } = props
    return (
        <React.Fragment>
            <div className={color && " colorstripe"}></div>
        </React.Fragment>
    )
}