import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import './SinglePage.css'
import Footer from '../organisms/footer/footer'
import Header from '../organisms/header/header'
import MsgBox from '../molecules/msgBox/msgBox'
import Search from '../atoms/input/searchGeneric'
import SpIcon from '../atoms/spIcon/spIcon'

const baseUrl = process.env.REACT_APP_DATA_BASE_URL || '/'

enum ServiceStatusColor {
    OK = 'green',
    DEGRADED = 'yellow',
    DOWN = 'red',
    PARTIAL_OUTAGE = 'orange'
}

interface ServiceMapping {
    serviceId: string
    regionId: string
}

interface Service {
    id: string
    compartmentId: string
    name: string
    label: string
    serviceGroupId: string
    description: string
}

interface ServiceGroup {
    id: string
    compartmentId: string
    name: string
    label: string
    description: string
    serviceGroups?: ServiceGroup[]
}

interface Region {
    id: string
    compartmentId: string
    name: string
    label: string
    geoLocation: string
    description: string
}

interface PageData {
    id: string
    name: string
    compartmentId: string
    tenancyId: string
    label: string
    description: string
    serviceMapping: ServiceMapping[]
    displayView: string
    accessType: string
    visibility: string
    customUrl: string
    logoPath: string
    idcsDomain: string
    clientId: string
    services: Service[]
    serviceGroups: ServiceGroup[]
    regions: Region[]
}

interface Update {
    id: string
    compartmentId: string
    serviceEventId: string
    description: string
    customerImpact: string
    status: string
    timeCreated: string
    timeStarted: string
    timeEnded: string
    timeNextUpdate: string
    affectedServices: {
        serviceId: string
        regionId: string
        serviceStatus: string
    }[]
    lifecycleState: string
}

interface Event {
    id: string
    compartmentId: string
    name: string
    type: string
    referenceNumber: string
    parentTicketNumber: string
    timeCreated: string
    description: string
    updates: Update[]
}

const SinglePage: React.FC = () => {
    const { slug } = useParams<{ slug: string }>()
    const [pageData, setPageData] = useState<PageData | null>(null)
    const [eventsData, setEventsData] = useState<Event[]>([])
    const [activeGeo, setActiveGeo] = useState<string>('North America')
    const [showHistory, setShowHistory] = useState<boolean>(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch the page data
                const pageResponse = await fetch(
                    `${baseUrl}/pages/${slug}/page.json`
                )
                if (!pageResponse.ok)
                    throw new Error(
                        `Failed to fetch page data: ${pageResponse.statusText}`
                    )

                const pageText = await pageResponse.text()
                const pageData: PageData = JSON.parse(pageText)
                setPageData(pageData)

                // Fetch the events data using tenancyId
                const eventsUrl = `${baseUrl}/tenancies/${pageData.tenancyId}/events.json`
                const eventsResponse = await fetch(eventsUrl)
                if (!eventsResponse.ok)
                    throw new Error(
                        `Failed to fetch events data: ${eventsResponse.statusText}`
                    )

                const eventsText = await eventsResponse.text()
                // console.log('Events Text: ', eventsText)
                // const eventsData: Event[] = JSON.parse(eventsText)
                setEventsData(JSON.parse(eventsText).items)
            } catch (error: any) {
                console.error('Error fetching data:', error.message)
            }
        }

        fetchData()
    }, [slug])

    if (!pageData) {
        return <div>Loading (this is from react)...</div>
    }

    console.log('Events Data: ', eventsData)
    const geographies = Array.from(
        new Set(pageData.regions.map((region) => region.geoLocation))
    )

    const renderHeader = (geo: string) => {
        const regionsInGeo = pageData.regions.filter(
            (region) => region.geoLocation === geo
        )
        return (
            <tr className='sp-table-header-tr'>
                <th
                    style={{ textAlign: 'left' }}
                    className='sp-table-header-th1'
                >
                    {' '}
                </th>
                {regionsInGeo.map((region) => (
                    <th className='sp-table-header-th' key={region.id}>
                        {region.label}
                    </th>
                ))}
            </tr>
        )
    }

    const renderService = (
        service: Service,
        regionsInGeo: Region[],
        depth: number
    ) => (
        <tr key={service.id} className='sp-table-service-tr'>
            <td
                style={{ paddingLeft: `${20 + 20 * depth}px` }}
                className='sp-table-service-td-name'
            >
                {service.label}
            </td>
            {regionsInGeo.map((region) => {
                const hasActiveIncident = eventsData.some((event) =>
                    event.updates.some((update) =>
                        update.affectedServices.some(
                            (affectedService) =>
                                affectedService.serviceId === service.id &&
                                affectedService.regionId === region.id &&
                                update.status !== 'Resolved'
                        )
                    )
                )

                const statusColor = hasActiveIncident ? 'DEGRADED' : 'OK'

                return (
                    <td
                        key={region.id}
                        style={{ textAlign: 'center' }}
                        className='sp-table-service-td-status'
                    >
                        <SpIcon status={statusColor} />
                    </td>
                )
            })}
        </tr>
    )

    console.log('Service Groups: ', pageData.serviceGroups)
    console.log('Services: ', pageData.services)

    const renderServiceGroups = (
        serviceGroups: ServiceGroup[],
        regionsInGeo: Region[],
        depth: number = 0
    ) => {
        return serviceGroups.map((group) => (
            <React.Fragment key={group.id}>
                <tr className='sp-table-servicegroup-tr'>
                    <td
                        colSpan={regionsInGeo.length + 1}
                        style={{ paddingLeft: `${20 + 20 * depth}px` }}
                        className='sp-table-servicegroup-td'
                    >
                        {group.label}
                    </td>
                </tr>
                {pageData.services
                    .filter((service) => service.serviceGroupId === group.id)
                    .map((service) =>
                        renderService(service, regionsInGeo, depth + 1)
                    )}
                {group.serviceGroups &&
                    renderServiceGroups(
                        group.serviceGroups,
                        regionsInGeo,
                        depth + 1
                    )}
            </React.Fragment>
        ))
    }

    const renderBody = (geo: string) => {
        const regionsInGeo = pageData.regions.filter(
            (region) => region.geoLocation === geo
        )
        return (
            <>
                {pageData.services &&
                    pageData.services.map((service) =>
                        renderService(service, regionsInGeo, 0)
                    )}
                {pageData.serviceGroups &&
                    renderServiceGroups(
                        pageData.serviceGroups,
                        regionsInGeo,
                        0
                    )}
            </>
        )
    }

    console.log('Events Data: ', eventsData)
    const renderIncidents = () => {
        const incidentsToShow = showHistory
            ? eventsData
            : eventsData.filter((incident) =>
                  incident.updates.some(
                      (update) => update.status !== 'Resolved'
                  )
              )
        return incidentsToShow.map((incident) => (
            <div
                key={incident.id}
                style={{
                    border: '1px solid black',
                    margin: '10px 0',
                    padding: '10px'
                }}
            >
                <h2>{incident.name}</h2>
                <p>{incident.description}</p>
                <p>Status: {incident.type}</p>
                <p>Start Time: {incident.timeCreated}</p>
                <div>
                    <h4>Updates:</h4>
                    {incident.updates.map((update) => (
                        <div key={update.id}>
                            <p>
                                {new Date(update.timeCreated).toLocaleString()}:{' '}
                                {update.description} - {update.status}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        ))
    }

    return (
        <div>
            <Header title={pageData.label} isTopVisible={true} />
            <div className='topspacer'></div>
            {/* <MsgBox
                scheme='maintenance'
                title='Important Maintenance!'
                lead="Here's what you need to know."
                text='Detailed information about the update...'
                url='#'
            />
            <MsgBox
                scheme='warning'
                title='Important Update!'
                lead="Here's what you need to know."
                text='Detailed information about the update...'
                url='#'
            /> */}
            <div id='singepage_maindiv' className='centered-container'>
                <div className='fullsize-div'>
                    <div>
                        {eventsData.length > 0 && <h3>Incidents</h3>}
                        {renderIncidents()}
                        {/* <button
                            onClick={() => setShowHistory(!showHistory)}
                            style={{ marginTop: '10px' }}
                        >
                            {showHistory ? 'Hide History' : 'Show History'}
                        </button> */}
                    </div>
                    <div className='sp-main'>
                        <div
                            style={{
                                marginBottom: '50px',
                                paddingLeft: '20px',
                                paddingRight: '20px'
                            }}
                        >
                            <Search
                                id='sp-search'
                                placeholder='Search and Filter'
                                className='sp-search'
                                type='text'
                                change={() => {}}
                            />
                        </div>
                        <div className='sp-tab-div'>
                            {geographies.map((geo) => (
                                <button
                                    key={geo}
                                    onClick={() => setActiveGeo(geo)}
                                    className={`sp-tab ${
                                        geo === activeGeo ? 'active' : ''
                                    }`}
                                >
                                    {geo}
                                </button>
                            ))}
                            <div className='infoboxIcon'>
                                <SpIcon status='help' />
                            </div>
                        </div>
                        <table className='sp-table'>
                            <thead>{renderHeader(activeGeo)}</thead>
                            <tbody>{renderBody(activeGeo)}</tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SinglePage
