import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../organisms/footer/footer';

const baseUrl = process.env.REACT_APP_DATA_BASE_URL || 'https://example.com/';

interface Page {
  id: string;
  name: string;
  dashboardUrl: string;
}

interface PagesData {
  pages: Page[];
}

const PagesList: React.FC = () => {
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    const fetchPagesData = async () => {
      try {
        const response = await fetch(`/status-data/services-index.json`);
        const data: PagesData = await response.json();
        setPages(data.pages);
      } catch (error) {
        console.error('Failed to fetch pages data:', error);
      }
    };

    fetchPagesData();
  }, []);
  


  return (
    <div>
      <h1>Status Pages</h1>
      <ul>
        {pages.map((page) => (
          <li key={page.id}>
            <Link to={`/page/${page.id}`}>{page.name}</Link>
          </li>
        ))}
      </ul>
      <Footer />
    </div>
  );
};

export default PagesList;
