import React from 'react';
import './header.css'; 
import Ologo from '../../atoms/ologo/ologo'
import colorstripe from "../../atoms/colorstripe/colorstripe"
import ButtonResponsive from '../../atoms/button/buttonResponsive'

interface HeaderProps {
    title: string;
    isTopVisible: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, isTopVisible }) => {

    function showModal() {
        alert('Subscriber modal');
    }
    function clickfire() {
        alert('click');
    }

    return (
        <div className="header">
            <div className={isTopVisible?"":"hidden"}>
                <div className="black-top-header">
                    <div className="centered-container">
                        <div className="fullsize-div text-left">
                            <Ologo title={"Cloud Infrastructure"} />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="centered-container">
                    <div className="header-left text-left">
                        <h1 className="header-title">{title}</h1>
                    </div>
                    <div className="header-right text-right">
                        <ButtonResponsive
                            id='subscribe-btn'
                            buttonClass='btn btn--transparent'
                            contentClass='btn__content--responsive--desktop'
                            label='Incident history'
                            click={() => clickfire()}
                            type='button'
                            iconName='Incident history'
                            iconClass='oj-ux-ico-custom-events'
                        />

                        <ButtonResponsive
                            id='subscribe-btn'
                            buttonClass='btn btn--dark left-margin10'
                            contentClass='btn__content--responsive--desktop'
                            label='Subscribe to Alerts'
                            click={() => showModal()}
                            type='button'
                            iconName='Subscribe to Alerts'
                            iconClass='oj-ux-ico-email'
                        />
                    </div>
                </div>
            </div>
            {colorstripe({"color":"green"})}
        </div>
    );
};

export default Header;