import * as React from 'react'
import '../../atoms/input/input.css'
import InputGeneric from '../../atoms/input/inputGeneric'
import { GrFormSearch } from 'react-icons/gr'

export interface SearchProps {
    id: string
    placeholder: string
    className: string
    type: string
    change: any
}

export default function Search(props: SearchProps) {
    const { id, placeholder, className, type, change } = props

    return (
        <React.Fragment>
            <div className='search__container'>
                <span className='search__icon-wrapper'>
                    <GrFormSearch className='search__icon' />
                </span>
                <InputGeneric
                    className={className}
                    id={id}
                    placeholder={placeholder}
                    type={type}
                    change={change}
                />
            </div>
        </React.Fragment>
    )
}
