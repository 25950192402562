import React from "react";
import './footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <div className="centered-container">
                <div className="footer-left">
                    <a href="#">Terms of Use and Privacy</a>
                    <a href="#">Cookie Preferences</a>
                </div>
                <div className="footer-right">
                    2024, Oracle and/or its affiliates. All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer;