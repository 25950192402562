import * as React from "react"

export interface IconProps {
    name: string
    className: string
}

export default function Icon(props: IconProps) {
    const { name, className } = props

    return (

        <React.Fragment>
            <i title={name} className={className}></i>
        </React.Fragment>
    )
}


