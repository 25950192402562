import { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import PagesList from './components/pages/PagesList'
import SinglePage from './components/pages/SinglePage'

const baseUrl = process.env.REACT_APP_DATA_BASE_URL || 'https://example.com/'

interface Page {
    id: string
    name: string
    dashboardUrl: string
}

interface PagesData {
    pages: Page[]
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<PagesList />} />
                <Route path='/:slug' element={<SinglePage />} />
            </Routes>
        </Router>
    )
}

export default App
