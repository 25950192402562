import * as React from "react"
import "./input.css"

export interface InputProps {
    id: string
    placeholder: string
    change: any
    className: string
    type: string
    value?: string
    disabled?:boolean
    min?: string
}

export default function inputGeneric(props: InputProps) {
    const { id, placeholder, change, className, type, value, disabled, min } = props

    return (
        <React.Fragment>
            <input
                id={id}
                value={value}
                type={type}
                className={className}
                placeholder={placeholder}
                onChange={change}
                disabled={disabled}
                min={min}
            />
        </React.Fragment>
    )
}
