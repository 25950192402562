import * as React from 'react'
import Icon from '../icon/icon'
import Spinner from '../spinner/Spinner'

export interface ButtonProps {
    id: string
    label: string
    buttonClass: string
    click: any
    type: any
    iconName: string
    iconClass: string
    contentClass: string
    loading?: boolean
    disabled?: boolean
}

export default function ButtonResponsive(props: ButtonProps) {
    const {
        id,
        label,
        buttonClass,
        click,
        type,
        iconName,
        iconClass,
        contentClass,
        loading = false,
        disabled = false,
    } = props

    // TODO:  Need some styling for when disabled is true.  Grey out, remove the pointer or render a "disabled" sign
    return (
        <React.Fragment>
            <button
                id={id}
                disabled={disabled}
                className={buttonClass}
                onClick={click}
                type={type}
            >
                <span className='btn__content--responsive--mobile'>
                    <Icon name={iconName} className={iconClass} />
                </span>
                {loading ? (
                    <span className={contentClass}>{<Spinner />}</span>
                ) : (
                    <span className={contentClass}>{label}</span>
                )}
            </button>
        </React.Fragment>
    )
}
