import React from 'react'
import { ReactComponent as NormalIcon } from './normal.svg'
import { ReactComponent as InfoIcon } from './info.svg'
import { ReactComponent as DisruptionIcon } from './disruption.svg'
import { ReactComponent as DownIcon } from './down.svg'
import { ReactComponent as HelpIcon } from './help.svg'

interface SpIconProps {
    status: string
}

const SpIcon: React.FC<SpIconProps> = ({ status }) => {
    let IconComponent

    switch (status.toUpperCase()) {
        case 'OK':
            IconComponent = NormalIcon
            break
        case 'DEGRADED':
        case 'PARTIAL_OUTAGE':
            IconComponent = InfoIcon
            break
        case 'DOWN':
            IconComponent = DownIcon
            break
        case 'HELP':
            IconComponent = HelpIcon
            break
        default:
            IconComponent = null
            break
    }

    return (
        <div title={status}>
            {IconComponent ? <IconComponent /> : <span>x</span>}
        </div>
    )
}

export default SpIcon
