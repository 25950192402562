// Spinner.tsx
import React from 'react'
import styled, { keyframes } from 'styled-components'

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledSpinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #bb501c;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: ${spinAnimation} 1s linear infinite;
`

const Spinner: React.FC = () => {
    return <StyledSpinner />
}

export default Spinner
