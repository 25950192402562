import React from "react";
import './ologo.css'
import { ReactComponent as Logo } from './ologo.svg';

interface OlogoProps {
    title: string;
}

const Ologo: React.FC<OlogoProps> = ({ title }) => {
    return (
        <div className="ologo">
            <Logo className="ologo-o-img" />
            <div className="ologo-title">{title}</div>
        </div>
    )
}

export default Ologo;